<template>
    <v-container fluid>
        <v-row>
            <v-col cols="10" style="border-right: 1px solid lightgray">
                <h1>Neuer Tarif</h1>
                <v-divider></v-divider>
                <TarifNew></TarifNew>
            </v-col>
            <v-col cols="2">
                <h1>Rechner</h1>
                <v-divider></v-divider>
                <EffektivpreisKalkulator></EffektivpreisKalkulator>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import TarifNew from '@/components/TarifNew';
    import EffektivpreisKalkulator from '@/components/EffektivpreisKalkulator';

    export default {
        name: "NewTarif",
        components: {EffektivpreisKalkulator, TarifNew}
    }
</script>

<style scoped>

</style>
