<template>
    <div>
        <v-form>
            <v-text-field type="text"
                          v-model="tarif_model.link"
                          label="Link"
            >
            </v-text-field>
            <v-select
                    v-model="tarif_model.anbieter_id"
                    :items="anbieter"
                    item-value="id"
                    label="Anbieter"
                    required
            >
                <template v-slot:item="{item}">
                    {{item.name}}
                </template>
                <template v-slot:selection="{ item }">
                    {{item.name}}
                </template>
            </v-select>
            <v-text-field
                    v-model="tarif_model.name"
                    label="Name"
                    required
            ></v-text-field>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="tarif_model.grundpreis"
                            label="Grundpreis"
                            type="number"
                            suffix="€"
                            required
                            v-on:input="changed('grundpreis')"
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="tarif_model.installations_preis"
                            label="Installationspreis"
                            type="number"
                            suffix="€"
                            required
                            v-on:input="changed('installationspreis')"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="tarif_model.bandbreite_down"
                            label="Bandbreite Down"
                            type="number"
                            suffix="MBit/s"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="tarif_model.bandbreite_up"
                            label="Bandbreite Up"
                            type="number"
                            suffix="MBit/s"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-text-field
                    v-model.number="tarif_model.vertragslaufzeit"
                    label="Vertragslaufzeit"
                    type="number"
                    suffix="Monate"
                    required
            ></v-text-field>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="ep2"
                            label="Effektivpreis 2 Jahre"
                            type="number"
                            suffix="€"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="ep5"
                            label="Effektivpreis 5 Jahre"
                            type="number"
                            suffix="€"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-text-field v-model="tarif_model.region" label="Verfügbar in Region"></v-text-field>
            <v-checkbox
                    v-model="tarif_model.is_flat"
                    label="Flatrate"
            ></v-checkbox>
            <v-row>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.is_dsl"
                            label="DSL"
                    ></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.is_cable"
                            label="Cable"
                    ></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.is_fiber"
                            label="Fiber"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.has_tv"
                            label="TV"
                    ></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.has_phone_flat"
                            label="Festnetzflat"
                    ></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.has_handy_flat"
                            label="Handyflat"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-textarea
                    v-model="tarif_model.text"
                    label="Zusätzlicher Informationstext"
            >
            </v-textarea>
            <v-spacer></v-spacer>
            <v-btn block @click="saveTarif" color="primary">Speichern</v-btn>
        </v-form>
        <v-spacer/>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Tarif from '@/store/models/Tarif';

    export default {
        name: "TarifNew",
        beforeDestroy() {
            this.$store.dispatch('calculator/clearModel')
        },
        computed: {
            anbieter: {
                get() {
                    return this.$store.$db().model('anbieter').query().all()
                }
            },
            ep2: {
                get() {
                    return this.$store.getters['calculator/getEffektivPreis2']
                },
                set(val) {
                    this.$store.dispatch('calculator/setEffektivPreis2', val)
                }
            },
            ep5: {
                get() {
                    return this.$store.getters['calculator/getEffektivPreis5']
                },
                set(val) {
                    this.$store.dispatch('calculator/setEffektivPreis5', val)
                }
            },
            ...mapGetters('oidcStore', [
                'oidcIsAuthenticated',
                'oidcAccessToken',
                'oidcError'
            ])
        },
        data() {
            return {
                tarif_model: new Tarif(),
                dialog: null,
                save_counter: 0
            }
        },
        methods: {
            ...mapActions('oidcStore', [
                'authenticateOidcSilent', // Authenticates if signed in. No redirect is made if not signed in
            ]),
            async saveTarif() {
                if (!this.oidcIsAuthenticated) {
                    this.save_counter++
                    this.authenticateOidcSilent().then(
                        () => {
                            if (this.save_counter < 3) {
                                this.saveTarif()
                            }
                        }
                    )
                    return
                }
                this.tarif_model.updated = new Date().toISOString()
                this.tarif_model.effektiv_preis2 = this.ep2
                this.tarif_model.effektiv_preis5 = this.ep5
                const model = this.$store.$db().model('tarif')
                await model.api().post(`/api/v1/tarif/`, this.tarif_model, {
                    headers: {'Authorization': `Bearer ${this.oidcAccessToken}`}
                })
                this.save_counter = 0
                await this.$router.push({path: '/'})
            },
            changed(what) {
                switch (what) {
                    case 'grundpreis': {
                        this.$store.dispatch('calculator/setBasePrice', this.tarif_model.grundpreis)
                        break
                    }
                    case 'installationspreis': {
                        this.$store.dispatch('calculator/setAdditionalCosts', this.tarif_model.installations_preis)
                        break
                    }
                }
            }
        },
    }
</script>

<style scoped>

</style>
