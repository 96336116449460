<template>
    <div>
        <v-text-field v-model.number="base_price" label="Grundpreis" @change="calc(2)"></v-text-field>
        <v-text-field v-model.number="reduced_price" label="Reduzierter Preis"></v-text-field>
        <v-text-field v-model.number="months_reduced_price" label="Reduzierte Monate"></v-text-field>
        <v-text-field v-model.number="additional_costs" label="Zusatzkosten"></v-text-field>
        <v-text-field v-model.number="ep2" label="2 Jahre"></v-text-field>
        <v-text-field v-model.number="ep5" @change="calc(5)" label="5 Jahre"></v-text-field>
    </div>
</template>

<script>
    export default {
        name: "EffektivpreisKalkulator",
        computed: {
            base_price: {
                get() {
                    return this.$store.getters['calculator/getBasePrice']
                },
                set(val) {
                    this.$store.dispatch('calculator/setBasePrice', val)
                }
            },
            additional_costs: {
                get() {
                    return this.$store.getters['calculator/getAdditionalCosts']
                },
                set(val) {
                    this.$store.dispatch('calculator/setAdditionalCosts', val)
                }
            },
            ep2: {
                get() {
                    return this.$store.getters['calculator/getEffektivPreis2']
                },
                set(val) {
                    this.$store.dispatch('calculator/setEffektivPreis2', val)
                }
            },
            ep5: {
                get() {
                    return this.$store.getters['calculator/getEffektivPreis5']
                },
                set(val) {
                    this.$store.dispatch('calculator/setEffektivPreis5', val)
                }
            },
        },
        data() {
            return {
                reduced_price: 0.0,
                months_reduced_price: 6,
            }
        },
        methods: {
            calc(years) {
                const months = years * 12
                const fullprice = (months - this.months_reduced_price) * this.base_price
                const reducedprice = +this.months_reduced_price * this.reduced_price
                return (fullprice + reducedprice + this.additional_costs) / months
            }
        },
        watch: {
            base_price() {
                this.ep2 = this.calc(2)
                this.ep5 = this.calc(5)
            }
        }
    }
</script>

<style scoped>

</style>
